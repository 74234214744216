/**
* jQuery Plugin. 
* Enables key (Up, Down, Right, Left) focus navigation on an HTML table.
*
* Remarks.
* The td and th elements within the table must have the tabindex attribute set
* to a valid value that enables the focus. The plugin does not add css classes 
* to visually highlight elements as selected.
*
* Example.
* $("table").tableKeyNavigator();
*/
(
    function($){
        $.fn.tableKeyNavigator = function()
        {
            /**
             * Binds keypress events to allow keybased navigation.
             * @param {jQuerySelector} table 
             */
            function bindKeyNavigationEvents(table)
            {
                var down = 13;
                
                /* Binds to the keydown event on all th and td elements. */
                table.find("td,th").on("keydown",
                    function(e){
                        /* Gets the source element for the event. */
                        var sender = $(this);

                        /* Gets the parent tr. */
                        var tr = sender.parent();

                        /* Gets the position of the current element (td or th) in the current TR. */
                        var index = tr.find("td,th").index(sender[0]);
                        
                        /* Handles the direction of the focus. */
                        switch(e.key)
                        {
                            case "ArrowDown":
                                /* Gets the tr below. */
                                var nextTr = tr.next();
                                if (nextTr.length == 0)
                                {
                                    if (tr.parent()[0].tagName == "THEAD")
                                    {
                                        nextTr = table.find("tbody tr:first-child");
                                    }
                                }

                                /* Focuses the cell in the row below using the same index. */
                                var cells = nextTr.find("td,th");
                                $(cells[index]).focus();
                                e.preventDefault();

                                break;
                            case "ArrowUp":
                                /* Gets the tr above. */
                                var nextTr = tr.prev();
                                if (nextTr.length == 0)
                                {
                                    if (tr.parent()[0].tagName == "TBODY")
                                    {
                                        nextTr = table.find("thead tr");
                                    }
                                }

                                /* Focuses the cell in the row above using the same index. */
                                var cells = nextTr.find("td,th");
                                $(cells[index]).focus();
                                e.preventDefault();

                                break;
                            case "ArrowLeft":
                                /* Moves the current index to the left. */
                                if (index > 0)
                                {
                                    index --;
                                }

                                /* Focuses the cell.*/
                                $(tr.find("td,th")[index]).focus();
                                e.preventDefault();

                                break;
                            case "ArrowRight":
                                /* Moves the current index to the right. */
                                var cells = tr.find("td,th");
                                var currentIndex = cells.index(document.activetable);
                                if (currentIndex < cells.length - 1)
                                {
                                    index ++;
                                }

                                /* Focuses the cell.*/
                                $(tr.find("td,th")[index]).focus();
                                e.preventDefault();

                                break;
                        }
                        
                        
                        
                    }
                );
            };
            
            /* The plugging supports multiple table elements selected by the jQuery selector. */
            this.each(
                function(){
                    var instance = $(this);
                    
                    bindKeyNavigationEvents(instance);
                }
            );
        }
    }
)(jQuery);